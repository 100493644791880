import React, { useEffect, useState } from "react";
import style from "./summarystep.module.css";
import { FormCheck } from "react-bootstrap";
import validator from "validator";

const SummaryStep = ({
  selectedProjectSpace,
  selectedProjects,
  confluencePage,
  addAdditionalEmailRecipients,
  setAddAdditionalEmailRecipients,
  emailError,
  setEmailError
}) => {
  const [
    shouldAddAdditionalEmailRecipients,
    setShouldAddAdditionalEmailRecipients,
  ] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");

  useEffect(() => {
    if (!emailError) {
      setCurrentEmail('');
    }
  }, []);

  const handleAddAdditionalEmailRecipients = () => {
    setShouldAddAdditionalEmailRecipients((prevState) => !prevState);
  };

  const handleInputChange = (event) => {
    const input = event.target.value;

    if (input.includes(",") || input.includes(";") || input.includes(" ")) {
      const potentialEmails = input.split(/[\s,;]+/);
      const newEmails = [];
      potentialEmails.forEach((email) => {
        if (validator.isEmail(email)) {
          if (!addAdditionalEmailRecipients.includes(email) && !newEmails.includes(email)) {
            newEmails.push(email);
          } else {
            setEmailError(`The email ${email} is already added.`);
          }
        } else {
          setEmailError(`The email ${email} is not a valid email address.`);
        }
      });
      if (newEmails.length > 0) {
        setAddAdditionalEmailRecipients(prevEmails => [...prevEmails, ...newEmails]);
        setCurrentEmail("");
      }
    } else {
      setCurrentEmail(input);
    }
    setEmailError("");
  };

  const handleOneAdditionalEmailRecipient = () => {
    if (currentEmail) {
      if (validator.isEmail(currentEmail)) {
        setAddAdditionalEmailRecipients((prevEmails) => {
          if (!prevEmails.includes(currentEmail)) {
            return [...prevEmails, currentEmail];
          } else {
            setEmailError(`The email ${currentEmail} is already added.`);
            return prevEmails;
          }
        });
      } else {
        setEmailError(
          `The email ${currentEmail} is not a valid email address.`
        );
      }
    }
    !emailError && setCurrentEmail('')
  };

  const handleAdditionalEmailRecipients = (event) => {
    if (["Enter", ",", ";", " ", "Tab"].includes(event.key)) {
      event.preventDefault();
      const email = currentEmail.trim();
      if (validator.isEmail(email)) {
        if (!addAdditionalEmailRecipients.includes(email)) {
          setAddAdditionalEmailRecipients((prevEmails) => [
            ...prevEmails,
            email,
          ]);
          setCurrentEmail("");
        } else {
          setEmailError(`The email ${email} is already added.`);
        }
      } else {
        setEmailError(`The email ${email} is not a valid email address.`);
      }
    }
  };

  const handleDelete = (emailToDelete) => {
    setAddAdditionalEmailRecipients(
      addAdditionalEmailRecipients.filter((email) => email !== emailToDelete)
    );
  };

  return (
    <div className="d-flex flex-column mt-5 mx-4">
      <div>
        <h2 className={`${style.header}`}>Create Documentation</h2>
        <div className="d-flex flex-column mt-3">
          <div className="lead fs-4 mb-3 ms-3">
            <strong>Project Space:</strong> {selectedProjectSpace}
          </div>
          <div className="lead fs-4 mb-3 ms-3">
            <strong>Projects:</strong>{" "}
            {selectedProjects.map((project) => project.value).join()}
          </div>
          <div className="lead fs-4 mb-3 ms-3">
            <strong>Confluence Page:</strong> {confluencePage}
          </div>
        </div>
        <div className="custom-margin">
          <FormCheck
            id="add-additional-email-recipients"
            type="checkbox"
            label="Add additional email recipients"
            checked={shouldAddAdditionalEmailRecipients}
            onChange={handleAddAdditionalEmailRecipients}
          />
          {!shouldAddAdditionalEmailRecipients ? (
            <label className="fs-7 custom-text-color">
              * By default only current user will receive email notification
            </label>
          ) : null}
        </div>
        {shouldAddAdditionalEmailRecipients ? (
          <>
            <div className={`${style.emailInputContainer}`}>
              <div className={`${style.emailChipsContainer}`}>
                {addAdditionalEmailRecipients.map((email) => (
                  <div key={email} className={`${style.emailChip}`}>
                    {email}
                    <button
                      onClick={() => handleDelete(email)}
                      className={`${style.emailDelete} custom-margin-left`}
                    >
                      ×
                    </button>
                  </div>
                ))}
                <input
                  id="email-recepients"
                  size="lg"
                  placeholder={
                    addAdditionalEmailRecipients.length === 0
                      ? "Email recipients"
                      : ""
                  }
                  value={currentEmail}
                  onChange={handleInputChange}
                  onKeyDown={handleAdditionalEmailRecipients}
                  onBlur={handleOneAdditionalEmailRecipient}
                  className={`${style.emailInputField} p-1 border-0 custom-outline`}
                />
                {emailError && (
                  <div className={`${style.emailError}`}>{emailError}</div>
                )}
              </div>
            </div>
            <label className="fs-7 custom-text-color">
              * Comma separated emails
            </label>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default SummaryStep;
