import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Spinner, Stack } from "react-bootstrap";
import Input from "../../components/Input";
import InfoIcn from "../../assets/images/info.png";
import Button from "../../components/Button";
import { formatUrl, get, post } from "../../utils/api";
import { useSelectedOrganization } from "../../contexts/SelectedOrganizationContext";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../../components/Modal";

function Sharepoint() {
  const [spinner, setSpinner] = useState(false);
  const [noData, setNoData] = useState(false);
  const [userRole, setUserRole] = useState(false);
  const selectedOrganization = useSelectedOrganization();
  const [showModal, setShowModal] = useState(false);
  const [sharepointObj, setSharepointObj] = useState({
    isAdmin: false,
    sharepoint_site: "",
    sharepoint_tenant_name: "",
    sharepoint_tenant_id: "",
    sharepoint_client_id: "",
    sharepoint_client_secret: ""
  });

  const callApi = async () => {
    const url = formatUrl("user/profile", {
      organizationName: selectedOrganization,
    });
    const responseProfile = await get(url);
    setUserRole(responseProfile.data[0].userRole);
  };

  useEffect(() => {
    if (selectedOrganization) {
      callApi();
    }
  }, [selectedOrganization]);

  const sharepointObjectHandler = (e) => {
    setSharepointObj((previous) => ({
      ...previous,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    let isCancelled = false;

    const callApi = async () => {
      setSpinner(true);
      setNoData(false);
      let newSharepointObj = {
        sharepoint_site: "",
        sharepoint_tenant_name: "",
        sharepoint_tenant_id: "",
        sharepoint_client_id: "",
        sharepoint_client_secret: ""
      };

      try {
        const response = await get(
          `spconfig?organization=${encodeURIComponent(selectedOrganization)}`
        );

        if (!response) {
          setSharepointObj((prev) => ({ ...prev, isAdmin: true }));
          return;
        }

        if (response.data.sharepoint_site === null) {
          setNoData(true);
          setSharepointObj(newSharepointObj);
        } else {
          newSharepointObj = {
            sharepoint_site: response.data.sharepoint_site,
            sharepoint_tenant_name: response.data.sharepoint_tenant_name,
            sharepoint_tenant_id: response.data.sharepoint_tenant_id,
            sharepoint_client_id: response.data.sharepoint_client_id,
            sharepoint_client_secret: response.data.sharepoint_client_secret,
            isAdmin: response.data.isAdmin,
          };

          if (!isCancelled) {
            setSharepointObj(newSharepointObj);
          }
        }
      } catch (error) {
        if (!isCancelled) {
          toast.error(error.response.data.name);
        }
      } finally {
        setSpinner(false);
      }
    };

    if (selectedOrganization) {
      callApi();
    }

    return () => {
      isCancelled = true;
    };
  }, [selectedOrganization]);

  const updateSharepointObj = async (e) => {
    if (!sharepointObj.isAdmin) {
      return;
    }
    e.preventDefault();
    setSpinner(true);

    try {
      const response = await post("spconfig", {
        organization: selectedOrganization,
        sharepoint_site: sharepointObj.sharepoint_site,
        sharepoint_tenant_name: sharepointObj.sharepoint_tenant_name,
        sharepoint_tenant_id: sharepointObj.sharepoint_tenant_id,
        sharepoint_client_id: sharepointObj.sharepoint_client_id,
        sharepoint_client_secret: sharepointObj.sharepoint_client_secret
      });
      setNoData(false);
      toast.success(
        response.statusText + ".\nPlease refresh New Document page." ||
          "Sharepoint settings are successfully updated!"
      );
    } catch (error) {
      toast.error(error.response.data.name || "Update sharepoint error!");
    } finally {
      setSpinner(false);
    }
  };

  const deleteSharepointObj = async (e) => {
    if (!sharepointObj.isAdmin) {
      return;
    }
    e.preventDefault();
    setSpinner(true);

    try {
      const response = await post("spconfig", {
        organization: selectedOrganization,
        sharepoint_site: "Please Change",
        sharepoint_tenant_name: "Please Change",
        sharepoint_tenant_id: "Please Change",
        sharepoint_client_id: "Please Change",
        sharepoint_client_secret: "Please Change"
      });

      let newSharepointObj = {
        sharepoint_site: "",
        sharepoint_tenant_name: "",
        sharepoint_tenant_id: "",
        sharepoint_client_id: "",
        sharepoint_client_secret: "",
        isAdmin: true
      };

      setSharepointObj(newSharepointObj);
      setShowModal(false)
      toast.success("Sharepoint settings are successfully deleted!");
    } catch (error) {
      toast.error(error.response.data.name || "Delete sharepoint error!");
    } finally {
      setSpinner(false);
    }
  };

  const modalButtons = (
    <div className="d-flex justify-content-between align-items-end w-100">
      <Button id="cancelButton" onClick={() => setShowModal(false)}>
        Cancel
      </Button>
      <Button
        id="create-new-organization-summary"
        onClick={deleteSharepointObj}
      >
        Confirm
      </Button>
    </div>
  );

  return (
    <>
      <form className="px-5">
        {noData ? (
          <p>No data for selected organization: {selectedOrganization}.</p>
        ) : null}
        <Input
          disabled={spinner || !sharepointObj.isAdmin || userRole !== 2}
          type="text"
          placeholder="Enter Sharepoint site"
          label="Sharepoint site"
          name="sharepoint_site"
          id="site"
          value={sharepointObj.sharepoint_site}
          onChange={sharepointObjectHandler}
        />
        <Input
          disabled={spinner || !sharepointObj.isAdmin || userRole !== 2}
          type="text"
          placeholder="Enter Sharepoint Tenant Name"
          label="Sharepoint Tenant Name"
          name="sharepoint_tenant_name"
          id="tenant"
          value={sharepointObj.sharepoint_tenant_name}
          onChange={sharepointObjectHandler}
        />
        <Input
          disabled={spinner || !sharepointObj.isAdmin || userRole !== 2}
          type="text"
          placeholder="Enter Tenant ID"
          label="Tenant ID"
          name="sharepoint_tenant_id"
          id="tenantId"
          value={sharepointObj.sharepoint_tenant_id}
          onChange={sharepointObjectHandler}
        />
        <Input
          disabled={spinner || !sharepointObj.isAdmin || userRole !== 2}
          type="text"
          placeholder="Enter Sharepoint Client ID"
          label="Sharepoint Client ID"
          name="sharepoint_client_id"
          id="clientId"
          value={sharepointObj.sharepoint_client_id}
          onChange={sharepointObjectHandler}
        />
        {userRole === 2 ? (
          <>
            <Input
              disabled={spinner || userRole !== 2}
              type="text"
              placeholder="Enter Sharepoint Client Secret"
              label="Sharepoint Client Secret"
              name="sharepoint_client_secret"
              id="clientSecret"
              value={sharepointObj.sharepoint_client_secret}
              onChange={sharepointObjectHandler}
            />
          <div className="d-flex justify-content-between align-items-end mx-1">
            <Stack direction="horizontal">
              <Button
                disabled={spinner || userRole !== 2}
                type="submit"
                size="lg"
                onClick={updateSharepointObj}
              >
                Save{" "}
              </Button>
              {spinner ? (
                <Spinner
                  animation="border"
                  variant="primary"
                  className="mx-4"
                />
              ) : null}
            </Stack>
            <Button
              disabled={
                spinner ||
                !sharepointObj.sharepoint_site ||
                !sharepointObj.sharepoint_tenant_name ||
                !sharepointObj.sharepoint_client_id ||
                !sharepointObj.sharepoint_client_secret ||
                !sharepointObj.sharepoint_tenant_id
              }
              type="button"
              size="lg"
              onClick={() => setShowModal(true)}
              className="mx-1"
            >
                Delete
              </Button>
            </div>
          </>
        ) : (
          <></>
        )}
      </form>
      <Modal
        id="create-document-modal"
        onHide={() => setShowModal(false)}
        show={showModal}
        title=""
        modalButtons={modalButtons}
        backdrop="static"
      >
        <div>
          Are you sure you want to delete the settings from the system ?
        </div>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default Sharepoint;
