import React from "react";
import PurposeAndScope from "./sections/PurposeAndScope";
import HowWeCollectData from "./sections/HowWeCollectData";
import Cookies from "./sections/Cookies";
import InformationWeCollectAndStore from "./sections/InformationWeCollectAndStore";
import HowWeUseYourPersonalData from "./sections/HowWeUseYourPersonalData";
import DataTransfers from "./sections/DataTransfers";
import DataRetention from "./sections/DataRetention";
import DataSecurity from "./sections/DataSecurity";
import ChildrenPersonalData from "./sections/ChildrenPersonalData";
import ContactInformation from "./sections/ContactInformation";
import ChangesToPrivacyNotice from "./sections/ChangesToPrivacyNotice";
import PrinciplesDataProtection from "./sections/PrinciplesDataProtection";
import OperationalData from "./sections/OperationalData";
import DataSharing from "./sections/DataSharing";
import PrivacyRights from "./sections/PrivacyRights";

function Content() {
  return (
    <div className="custom-flex-basis">
      <h3>Pete - Virtual Assistant for SnapLogic Documentation</h3>
      <p>Effective Date: 11/02/2023</p>
      <PurposeAndScope />
      <PrinciplesDataProtection />
      <InformationWeCollectAndStore />
      <OperationalData />
      <Cookies />
      <HowWeCollectData />
      <HowWeUseYourPersonalData />
      <DataSharing />
      <DataTransfers />
      <DataRetention />
      <DataSecurity />
      <ChildrenPersonalData />
      <PrivacyRights />
      <ContactInformation />
      <ChangesToPrivacyNotice />
    </div>
  );
}

export default Content;
