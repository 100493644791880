import React, { useState, forwardRef, useImperativeHandle } from "react";
import { toast } from "react-toastify";
import SnapLogicStep from "../../components/DocumentSteps/SnapLogicStep";
import { post } from "../../utils/api";
import { CREATE_DOCUMENT_SHAREPOINT_TABS } from "../../config/tabs";
import Tabs from "../../components/Tabs";
import Button from "../../components/Button";
import { useProjects } from "../../contexts/ProjectsContext";
import { useSelectedOrganization } from "../../contexts/SelectedOrganizationContext";
import SharepointStep from "../../components/DocumentSteps/SharepointStep";
import SharepointSummaryStep from "../../components/DocumentSteps/SharepointSummaryStep";

const SharepointMainSection = forwardRef(
  (
    {
      targetSystem,
      setShowModal,
      setGetExecutionIdFromResponse,
      setErrorModalMessage,
      setErrorModalAssets,
      setIsErrorModalOpen,
    },
    ref
  ) => {
    const {
      selectedProjectSpace,
      setSelectedProjectSpace,
      selectedProjects,
      setSelectedProjects,
      selectedProjectsWithConfigFiles,
      storeConfigFileLocationOnly,
      startsWithFilter,
      containsFilter,
    } = useProjects();

    const selectedOrganization = useSelectedOrganization();
    const [shouldCreateDefaultPage, setShouldCreateDefaultPage] =
      useState(true);

    const [selectedSharepointPage, setSelectedSharepointPage] =
      useState("Select Main Page");
    const [newSharepointPage, setNewSharepointPage] = useState("");

    const [isCurrentSelectedOrganization, setIsCurrentSelectedOrganization] =
      useState(false);
    const [activeKey, setActiveKey] = useState("snaplogic");

    const [isCreatingDocument, setIsCreatingDocument] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [shouldCreateProjectSpacePage, setShouldCreateProjectSpacePage] =
      useState(false);
    const [addAdditionalEmailRecipients, setAddAdditionalEmailRecipients] =
    useState([]);
    const [emailError, setEmailError] = useState("");

    const sharepointPage = shouldCreateDefaultPage
      ? selectedProjectSpace
      : selectedSharepointPage !== "Select Main Page"
        ? selectedSharepointPage
        : newSharepointPage;

    const nextButtonDisabled =
      !(selectedProjectSpace && selectedProjects.length > 0) ||
      (newSharepointPage.length < 1 &&
        shouldCreateDefaultPage === false &&
        currentStep !== 0 &&
        selectedSharepointPage === "Select Main Page");

    const onTabSelect = (key) => {
      const step = CREATE_DOCUMENT_SHAREPOINT_TABS.findIndex(
        (tab) => tab.eventKey === key
      );

      setActiveKey(key);
      setCurrentStep(step > 0 ? step : 0);
    };

    const onBackClick = () => {
      setActiveKey(CREATE_DOCUMENT_SHAREPOINT_TABS[currentStep - 1].eventKey);
      setCurrentStep(currentStep - 1);
    };

    const onNextClick = () => {
      setActiveKey(CREATE_DOCUMENT_SHAREPOINT_TABS[currentStep + 1].eventKey);
      setCurrentStep(currentStep + 1);
    };

    const onCreateClick = async (confirmed) => {
      setIsCreatingDocument(true);
      try {
        const response = await post(
          "spdocumentation",
          {
            SLOrganization: selectedOrganization,
            SLProjectSpace: selectedProjectSpace,
            SLProject: selectedProjects.map((project) => project.value).join(),
            SharepointMainPage: sharepointPage,
            configFiles: selectedProjectsWithConfigFiles,
            storePathOnly: storeConfigFileLocationOnly,
            confirmed: confirmed || false,
            projectSpacePage: shouldCreateProjectSpacePage,
            discardPipelines: {
              startsWith: startsWithFilter,
              contains: containsFilter,
            },
            additionalEmailRecipients: addAdditionalEmailRecipients,
          },
          { timeout: 10 * 60 * 1000 }
        );

        if (response?.data?.limitExcited) {
          setIsErrorModalOpen(true);

          setErrorModalMessage(response.data.message);

          if (response.data.requestConfirmation) {
            setErrorModalAssets(response.data.assets);
          }

          return;
        }

        setGetExecutionIdFromResponse(response.data[0]);
        toast.success(response.data[1]);
        setShowModal(true);
        setCurrentStep(0);
        setSelectedSharepointPage("Select Main Page");
        setNewSharepointPage("");
        setSelectedProjectSpace("");
        setSelectedProjects([]);
        setIsCreatingDocument(false);
        setAddAdditionalEmailRecipients([]);
      } catch (error) {
        toast.error(error.response.data.name || "Something went wrong!");
        setSelectedSharepointPage("Select Main Page");
        setNewSharepointPage("");
        setSelectedProjectSpace("");
        setSelectedProjects([]);
        setIsCreatingDocument(false);
        setAddAdditionalEmailRecipients([]);
      }
    };

    useImperativeHandle(ref, () => ({
      clearFields() {
        setSelectedProjectSpace("");
        setSelectedProjects([]);
        setSelectedSharepointPage("Select Main Page");
        setNewSharepointPage("");
        setShowModal(false);
        setActiveKey("snaplogic");
      },
      confirmCreate() {
        onCreateClick(true);
      },
      handleModalClose() {
        setIsCreatingDocument(false);
      },
    }));

    return (
      <>
        <div className="px-5">
          <Tabs
            id="documents-page-tabs"
            className="mb-3"
            activeKey={activeKey}
            onSelect={(key) => onTabSelect(key)}
            variant="tabs"
            tabs={CREATE_DOCUMENT_SHAREPOINT_TABS}
          >
            <SnapLogicStep
              targetSystem={targetSystem}
              selectedOrganization={selectedOrganization}
              selectedProjectSpace={selectedProjectSpace}
              selectedProjects={selectedProjects}
              setSelectedProjectSpace={setSelectedProjectSpace}
              setSelectedProjects={setSelectedProjects}
              setIsCurrentSelectedOrganization={
                setIsCurrentSelectedOrganization
              }
              isCurrentSelectedOrganization={isCurrentSelectedOrganization}
            />
            <SharepointStep
              selectedOrganization={selectedOrganization}
              selectedSharepointPage={selectedSharepointPage}
              setSelectedSharepointPage={setSelectedSharepointPage}
              newSharepointPage={newSharepointPage}
              setNewSharepointPage={setNewSharepointPage}
              shouldCreateDefaultPage={shouldCreateDefaultPage}
              setShouldCreateDefaultPage={setShouldCreateDefaultPage}
            />
            <SharepointSummaryStep
              selectedProjectSpace={selectedProjectSpace}
              selectedProjects={selectedProjects}
              sharepointPage={sharepointPage}
              addAdditionalEmailRecipients={addAdditionalEmailRecipients}
              setAddAdditionalEmailRecipients={setAddAdditionalEmailRecipients}
              emailError={emailError}
              setEmailError={setEmailError}
            />
          </Tabs>
        </div>
        <div className="d-flex justify-content-between align-items-end mx-5 mt-3">
          <Button
            show={currentStep > 0 && currentStep < CREATE_DOCUMENT_SHAREPOINT_TABS.length}
            onClick={onBackClick}
            disabled={isCreatingDocument}
          >
            Back
          </Button>
          <Button
            show={currentStep < CREATE_DOCUMENT_SHAREPOINT_TABS.length}
            onClick={
              currentStep < CREATE_DOCUMENT_SHAREPOINT_TABS.length - 1
                ? () => onNextClick()
                : () => onCreateClick()
            }
            disabled={nextButtonDisabled || isCreatingDocument || emailError}
            showSpinner={isCreatingDocument}
          >
            {currentStep < CREATE_DOCUMENT_SHAREPOINT_TABS.length - 1 ? "Next" : "Create"}
          </Button>
        </div>
      </>
    );
  }
);

export default SharepointMainSection;
